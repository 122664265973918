<template>
    <div id="app">
        <div id="top" style="padding:10px;">招生平台数据详情</div>
        <div style="background:#fff">
        <a-table :columns="columns" :data-source="Datadeploy" rowKey="ID" :pagination="false" style="min-height:594px">
            <!--<a slot="name" slot-scope="text">{{ text }}</a>-->
            <span slot="PresidentName" slot-scope="text,record">
                <span v-if="record.PresidentName==null"><label>---校长名称未完善---</label></span>
                <span v-if="record.PresidentName!=null">{{record.PresidentName}}</span>
            </span>
            
        </a-table>
        </div>
        <a-pagination v-model="pageIndex" :total="pageSum" @change="deploy"  style="margin:10px auto;width:80%;text-align:center" />

    </div>
</template>
<script>
   
    import "ant-design-vue/dist/antd.css";
    import Vue from "vue";
    import ant from "ant-design-vue";
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
    import util from "@/Plugin/util.js"
    Vue.prototype.$zhCN = zh_CN;
    Vue.use(ant);
    import http from "@/Plugin/Http.js";
    const columns = [
        {
            title: '平台ID',
            dataIndex: 'ID',
            width: 300,
           /* key: 'name',*/
           /* scopedSlots: { customRender: 'name' },*/
        },
        {
            title: '机构名称',
            dataIndex: 'VisitName',
           /* key: 'age',*/
            width: 250,
        },
        {
            title: '校长名称',
            key:'PresidentName',
            dataIndex: 'PresidentName',
            scopedSlots: {
                customRender:'PresidentName'
            }
            //key: 'address 1',
            //ellipsis: true,
        },
        {
            title: '联系电话',
            dataIndex: 'PresidentPhone',
            //key: 'address 2',
            //ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'CreateTime',
            key: 'address 4',
            customRender: (text, item) => {
                var temp = new Date(util.TimeStamptoDateTime(item.CreateTime, 'yyyy-MM-dd hh:mm:ss'));
                return util.formatDate3(temp);
            }
        },
    ];
    export default {
        name: "",
        data() {
            return {
                columns,
                Datadeploy: [],
                pageSize:10,  
                pageIndex:1,
                pageSum: 1,
                TotalPage:0,

            };
        },

        methods: {
            //方法
            deploy: function () {
                var self = this;
                console.log(self.BeginDate, self.EndDate);
                var op = {
                    url: '/OperationsModule/Ranking/deploy',
                    data: {
                        pageSize: self.pageSize,
                        pageIndex: self.pageIndex,
                    },
                    OnSuccess: function (data) {
                        self.Datadeploy = data.data.Data;
                        self.pageSum = parseInt(data.data.Message);
                       
                        self.TotalPage = self.pageSum * self.pageSize;
                        //console.log("???");
                        //console.log(typeof (self.pageSum));
                        //console.log(data);
                    }
                };
                http.Post(op);
            },
        },
        computed: {
            //计算属性
        },
        watch: {
            //侦听属性
        },
        components: {
            //组件

        },
        beforeCreate: function () {
            //生命周期，创建前
        },
        created: function () {
            //生命周期，创建完成
        },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {
            //生命周期，组装完成
            var self = this;
            self.deploy();

        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            //生命周期，data更新完成
        },
        beforeDestroy: function () {
            //生命周期，销毁前
        },
        destroyed: function () {
            //生命周期，销毁完成
        },
    }
</script>
<style>
    #app {
        background-color: #F0F2f5;
    }

    #top {
        text-align: center;
        color: #186ee5;
        font-size: 2.5em;
        font-weight: 600;
    }

    .index-float {
    }

    .tow {
        width: 30%;
        height: 840px;
        float: left;
        background-color: #ffffff;
        margin-top: 20px;
        margin-left: 2.5%
    }
</style>
